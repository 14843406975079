import { ReceivedChatMessage } from "@livekit/components-react"
import { Avatar, Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import React from "react"

interface ChatBoxProps {
  item: ReceivedChatMessage
}

export function ChatBox({ item }: ChatBoxProps) {
  const isMessageFromAgent = item?.from.isAgent
  const name = isMessageFromAgent ? "Agent" : item.from.name

  return (
    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <Avatar sx={{ width: { xs: 28, sm: 40 }, height: { xs: 28, sm: 40 } }}>
          {item.from.name.charAt(0)}
        </Avatar>
        <Typography
          fontSize={{ xs: "0.75rem", sm: "0.875rem" }}
          variant="body1"
          color={"#220738"}
          fontWeight={600}
        >
          {name}
        </Typography>
        <Typography
          variant="body1"
          fontSize={{ xs: "0.625rem", sm: "0.75rem" }}
          color="gray"
        >
          {dayjs(item.timestamp).format("h:mm A")}
        </Typography>
      </Box>
      <Box paddingX={{ xs: "2rem", sm: "3rem" }}>
        <Box
          sx={{
            backgroundColor: isMessageFromAgent ? "#F5EFFF" : "#F5F5F7",
            padding: { xs: "0.5rem 0.75rem", sm: "0.75rem 1rem" },
            borderRadius: "1rem",
            borderTopLeftRadius: isMessageFromAgent ? "1rem" : "0.25rem",
            borderTopRightRadius: isMessageFromAgent ? "0.25rem" : "1rem",
            maxWidth: "80%",
            width: "fit-content",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: isMessageFromAgent ? "-8px" : undefined,
              right: isMessageFromAgent ? undefined : "-8px",
              borderTop: `8px solid ${isMessageFromAgent ? "#F5EFFF" : "#F5F5F7"}`,
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              transform: isMessageFromAgent ? "none" : "scaleX(-1)",
            },
          }}
        >
          <Typography
            variant="body1"
            color={"#220738"}
            fontSize={{ xs: "0.8rem", sm: "1rem" }}
            sx={{
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            {item?.message || "No message"}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
