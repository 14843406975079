import { LiveKitRoom } from "@livekit/components-react"
import "@livekit/components-styles"
import * as Sentry from "@sentry/react"
import { MediaDeviceFailure, Participant, VideoPresets } from "livekit-client"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import CallLoading from "../../components/CustomVideoConference/CallLoading"
import { logAnalyticsEvent } from "../../external/analytics"
import { getCallDuration, updateCallDuration } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import {
  deleteLivekitRoom,
  getLivekitToken,
  isDev,
  isLocalDev,
  isStaging,
} from "../../utils/general"
import { useAppContext, useAppDispatchContext } from "../Context"
import { MyVideoConference } from "./MyVideoConference"

const MAX_CALL_DURATION = 3 * 60 // 3 minutes
const IDLE_TIMEOUT = MAX_CALL_DURATION - 1 * 60 // 2 minutes
const BUFFER_TIME = 2 // 2 seconds

const serverUrl = isLocalDev
  ? "ws://localhost:7880"
  : isDev
    ? "wss://dev-38rwe5wn.livekit.cloud"
    : isStaging
      ? "wss://staging-56walglr.livekit.cloud"
      : "wss://prod-w0h88kyi.livekit.cloud"

export default function LiveRoom() {
  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const [token, setToken] = useState("")
  const [isConnected, setIsConnected] = useState(false)
  const [participants, setParticipants] = useState<Participant[]>([])
  const [lastMessageTime, setLastMessageTime] = useState(Date.now())
  const [duration, setDuration] = useState(0)
  const [maxCallDuration] = useState(MAX_CALL_DURATION)
  const [sessionStartTime] = useState(new Date(Date.now() + BUFFER_TIME * 1000))
  const [initialCallDuration, setInitialCallDuration] = useState(0)

  useEffect(() => {
    const fetchCallDuration = async () => {
      if (!id) return
      const duration = await getCallDuration(id)
      setInitialCallDuration(duration)
    }
    fetchCallDuration()
  }, [id])

  const roomOptions = {
    publishDefaults: {
      simulcast: true,
      videoSimulcastLayers: [VideoPresets.h540, VideoPresets.h360],
    },
    audioCaptureDefaults: {
      deviceId: context.audioDevice,
    },
    videoCaptureDefaults: {
      deviceId: context.videoDevice,
    },
  }

  useEffect(() => {
    const req = async () => {
      const tokenFromBe = await getLivekitToken(id)
      setToken(tokenFromBe)
    }
    req()
  }, [id])

  const onDisconnected = useCallback(async () => {
    try {
      const currentSessionDuration = Math.floor(
        (new Date().getTime() - sessionStartTime.getTime()) / 1000,
      )
      const finalDuration = currentSessionDuration + initialCallDuration

      logAnalyticsEvent(AnalyticsEvents.CallEnded, { duration: finalDuration })

      await updateCallDuration(id, { callDuration: finalDuration }).then(() => {
        if (participants.every((p) => p.isLocal || p.isAgent)) {
          deleteLivekitRoom(id)
        }
        navigate("/rooms/" + id + "/post")
      })
    } catch (error) {
      console.error("Error during disconnect:", error)
      navigate("/rooms/" + id + "/post")
    }
  }, [
    dispatch,
    id,
    initialCallDuration,
    navigate,
    participants,
    sessionStartTime,
  ])

  useEffect(() => {
    // timer to check if the user is idle
    const idleCheck = setInterval(() => {
      const idle = Date.now() - lastMessageTime
      if (idle >= IDLE_TIMEOUT * 1000) {
        logAnalyticsEvent(AnalyticsEvents.IdleTimeout)
        onDisconnected()
      }
    }, 1000)

    return () => clearInterval(idleCheck)
  }, [lastMessageTime, onDisconnected])

  useEffect(() => {
    // timer to check if the call should end
    if (maxCallDuration <= 0) return

    if (duration >= maxCallDuration) {
      onDisconnected()
    }
  }, [duration, maxCallDuration, onDisconnected])

  useEffect(() => {
    let timer: NodeJS.Timeout

    timer = setInterval(() => {
      const currentSessionDuration = Math.floor(
        (new Date().getTime() - sessionStartTime.getTime()) / 1000,
      )
      setDuration(currentSessionDuration + initialCallDuration)
    }, 1000)

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [sessionStartTime, initialCallDuration])

  // Memoize handlers
  const handleUserMessage = useCallback(() => {
    setLastMessageTime(Date.now())
  }, [])

  const handleMediaDeviceFailure = useCallback(
    (failure: MediaDeviceFailure) => {
      console.log("Media Device Failure:", failure)
      Sentry.captureMessage("Media Device Failure", {
        level: "error",
        tags: {
          "media.device.failure": failure.toString(),
        },
        extra: {
          mediaDeviceFailure: failure,
        },
      })
    },
    [],
  )

  const handleConnected = useCallback(() => {
    setIsConnected(true)
    const mediaStates = {
      audioDevice: context.audioDevice,
      videoDevice: context.videoDevice,
      audioEnabled: context.audioEnabled,
      videoEnabled: context.videoEnabled,
      roomOptions,
      audioProps: context.audioEnabled
        ? { deviceId: context.audioDevice }
        : false,
      connectionTime: new Date().toISOString(),
    }

    if (isDev && !isLocalDev) {
      console.log("LiveKit Room Connected - Media States:", mediaStates)

      Sentry.captureEvent({
        message: "LiveKit Room Media States",
        level: "info",
        tags: {
          "media.connection": "success",
          "media.audio.enabled": String(context.audioEnabled),
          "media.video.enabled": String(context.videoEnabled),
        },
        extra: {
          mediaStates,
        },
      })

      Sentry.addBreadcrumb({
        category: "media",
        message: "Media states after LiveKit room connection",
        level: "info",
        data: mediaStates,
      })

      Sentry.setContext("media_devices", mediaStates)
    }
  }, [
    context.audioDevice,
    context.videoDevice,
    context.audioEnabled,
    context.videoEnabled,
    roomOptions,
  ])

  const handleDisconnected = useCallback(() => {
    setIsConnected(false)
  }, [])

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      if (isConnected) {
        onDisconnected()
      }
      return undefined
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [isConnected, onDisconnected])

  return (
    <LiveKitRoom
      token={token}
      serverUrl={serverUrl}
      data-lk-theme="default"
      audio={context.audioEnabled}
      style={{ height: "100dvh" }}
      onDisconnected={onDisconnected}
      onConnected={handleConnected}
      options={roomOptions}
      onMediaDeviceFailure={handleMediaDeviceFailure}
    >
      {!isConnected && <CallLoading />}
      {isConnected && (
        <MyVideoConference
          setParticipants={setParticipants}
          maxDuration={maxCallDuration}
          onUserMessage={handleUserMessage}
          onDisconnected={onDisconnected}
          duration={duration}
        />
      )}
    </LiveKitRoom>
  )
}
