import React from "react"

import { Stack } from "@mui/material"
import { MediaSelect } from "./MediaSelect"
import { VideoOrPlaceholder } from "./VideoOrPlaceholder"

interface MediaSetupProps {
  setPermissionsGranted: (granted: boolean) => void
}

export function MediaSetup({ setPermissionsGranted }: MediaSetupProps) {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: "100%",
        maxWidth: "100%",
        position: "relative",
        "& > *": {
          maxWidth: "100%",
        },
      }}
    >
      <VideoOrPlaceholder setPermissionsGranted={setPermissionsGranted} />
      <MediaSelect />
    </Stack>
  )
}
