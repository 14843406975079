import { useLocalParticipant, useTrackToggle } from "@livekit/components-react"
import { Track, TrackPublication } from "livekit-client"
import React, { useEffect, useRef, useState } from "react"
import { Rnd } from "react-rnd"
import { useAppContext } from "../../app/Context"

interface UserVideoLiveKitProps {
  track?: TrackPublication
  chatEnabled: boolean
}

export function UserVideoLiveKit({
  track,
  chatEnabled,
}: UserVideoLiveKitProps) {
  const [dimensions, setDimensions] = useState({ width: 300, height: 150 })
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  const rndRef = useRef<Rnd>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  const context = useAppContext()
  const { localParticipant } = useLocalParticipant()

  const { enabled: isEnabled } = useTrackToggle({
    source: Track.Source.Camera,
    initialState: context.videoEnabled,
  })

  const aspectRatio = isMobile ? 1 / 1 : 16 / 9

  // Resize effect
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768
      setIsMobile(mobile)
      if (mobile) {
        const width = 100
        setDimensions({ width, height: width / aspectRatio })
      }
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const attachTrack = async () => {
      if (!track?.track || !videoRef.current) return

      if (!isEnabled) {
        videoRef.current.srcObject = null
        return
      }

      try {
        const mediaStream = new MediaStream()
        mediaStream.addTrack(track.track.mediaStreamTrack)
        videoRef.current.srcObject = mediaStream
        await videoRef.current.play()
      } catch (error) {
        console.error("Error attaching track:", error)
      }
    }

    attachTrack()

    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null
      }
    }
  }, [track, isEnabled])

  useEffect(() => {
    if (!videoRef.current || !track?.source || !context.videoDevice) return

    if (
      track.source === Track.Source.Camera &&
      context.stream instanceof MediaStream &&
      context.stream.getVideoTracks().length > 0
    ) {
      videoRef.current.srcObject = context.stream
    }
  }, [context.stream, context.videoDevice])

  useEffect(() => {
    if (rndRef.current && !isMobile) {
      const chatWidth = chatEnabled ? window.innerWidth * 0.25 : 0
      const newX = window.innerWidth - chatWidth - dimensions.width - 30
      const newY = window.innerHeight - dimensions.height - 100
      rndRef.current.updatePosition({ x: newX, y: newY })
    }
  }, [chatEnabled, dimensions.width, dimensions.height])

  const isVideoAvailable = localParticipant && track && isEnabled

  const bounds = {
    top: 0,
    left: 0,
    right:
      window.innerWidth -
      (chatEnabled ? window.innerWidth * 0.25 : 0) -
      dimensions.width,
    bottom: window.innerHeight - dimensions.height - 80,
  }

  return (
    <Rnd
      ref={rndRef}
      default={{
        x:
          window.innerWidth -
          (chatEnabled ? window.innerWidth * 0.25 : 0) -
          dimensions.width -
          (isMobile ? -140 : 60),
        y: window.innerHeight - dimensions.height - (isMobile ? 180 : 120),
        width: dimensions.width,
        height: dimensions.height,
      }}
      size={{ width: dimensions.width, height: dimensions.height }}
      minWidth={isMobile ? 80 : 150}
      minHeight={(isMobile ? 80 : 150) / aspectRatio}
      maxWidth={isMobile ? 120 : 400}
      maxHeight={(isMobile ? 120 : 400) / aspectRatio}
      bounds="window"
      lockAspectRatio={aspectRatio}
      enableResizing={{
        topLeft: true,
        bottomRight: true,
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomLeft: false,
      }}
      style={{
        ...(isMobile && {
          borderRadius: "50%",
          overflow: "hidden",
        }),
      }}
      resizeHandleComponent={{
        topLeft: (
          <div className="absolute -top-1 -left-1 w-4 h-4 cursor-nw-resize bg-white/20 rounded-tl-lg opacity-0 group-hover:opacity-100 transition-opacity">
            <div className="absolute top-0 left-0 w-full h-[2px] bg-white" />
            <div className="absolute top-0 left-0 h-full w-[2px] bg-white" />
          </div>
        ),
        bottomRight: (
          <div className="absolute -bottom-1 -right-1 w-4 h-4 cursor-se-resize bg-white/20 rounded-br-lg opacity-0 group-hover:opacity-100 transition-opacity">
            <div className="absolute bottom-0 right-0 w-full h-[2px] bg-white" />
            <div className="absolute bottom-0 right-0 h-full w-[2px] bg-white" />
          </div>
        ),
      }}
      dragHandleClassName="drag-handle"
      onDrag={(e, d) => {
        const x = Math.max(bounds.left, Math.min(d.x, bounds.right))
        const y = Math.max(bounds.top, Math.min(d.y, bounds.bottom))
        d.x = x
        d.y = y
      }}
      onResize={(e, direction, ref, delta, position) => {
        const width = ref.offsetWidth
        setDimensions({
          width,
          height: width / aspectRatio,
        })
      }}
    >
      <div className="relative w-full h-full bg-black overflow-hidden group rounded-lg shadow">
        <div
          className="drag-handle absolute top-0 left-0 w-full h-6 bg-gradient-to-b from-black/50 to-transparent 
                    opacity-0 group-hover:opacity-100 transition-opacity cursor-move"
        />
        {isVideoAvailable ? (
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            className="w-full h-full object-cover scale-x-[-1]"
          />
        ) : (
          <div className="w-full h-full bg-white/10 rounded-lg shadow"></div>
        )}
      </div>
    </Rnd>
  )
}
