import { Box, Stack, Typography } from "@mui/material"
import React from "react"

export function TitleTextButton({
  text,
  title,
  subtitle,
  button,
}: {
  text: JSX.Element
  title: string
  subtitle?: string
  button: JSX.Element
}) {
  return (
    <Stack direction="column" alignItems={"flex-start"}>
      <Typography
        variant="body1"
        textAlign={"left"}
        sx={{ fontSize: "24px", fontWeight: 500, lineHeight: "110%" }}
        mb={3}
      >
        {title}
      </Typography>

      {subtitle && (
        <Typography
          variant="body1"
          sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "130%" }}
          mb={2}
        >
          {subtitle}
        </Typography>
      )}

      {button}

      <Box sx={{ mt: 2, fontSize: "12px", textAlign: "left" }}>{text}</Box>
    </Stack>
  )
}
