import React from "react"

import {
  Avatar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppContext } from "../app/Context"
import { signUserOut } from "../external/auth"
import { Logo } from "./logo"

export function Header() {
  const context = useAppContext()
  const user = context.user
  const navigate = useNavigate()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const signOut = async () => {
    await signUserOut()
    navigate("/")
  }

  return (
    <>
      {isMobile ? (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ height: "10vh" }}
        >
          <Logo />
        </Stack>
      ) : (
        <Toolbar sx={{ height: "10vh" }}>
          <Logo />
          {user != null ? (
            <Box sx={{ dislay: "flex", alignItems: "center", flexGrow: 0 }}>
              <Stack direction="row" spacing={2}>
                <Stack alignItems="flex-end">
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {user.email}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={signOut}
                    sx={{ textTransform: "none", color: "black" }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      color={"#4019B6"}
                    >
                      Switch account
                    </Typography>
                  </Button>
                </Stack>
                <Avatar>{user.email[0]}</Avatar>
              </Stack>
            </Box>
          ) : null}
        </Toolbar>
      )}
    </>
  )
}
