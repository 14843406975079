import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AccountMobile } from "../../components/accountMobile"
import Background from "../../components/background"
import { Header } from "../../components/header"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { verifyAgentExists } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { SnackbarType } from "../../types/UI"
import { getAvailableCredits } from "../../utils/func"
import {
  getAgentUsage,
  getCapacity,
  getDefaultAgentId,
} from "../../utils/general"
import { useAppContext } from "../Context"
import { MediaSetup } from "./MediaSetup"
import { UserInput } from "./UserInput"

const sendAnalyticsEvent = () => {
  logAnalyticsEvent(AnalyticsEvents.AgentVisited)
}

export function PreScreen() {
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const [capacity, setCapacity] = useState<boolean>(false)
  const [overUsage, setOverUsage] = useState<boolean>(false)
  const [agentError, setAgentError] = useState<string>("")
  const [agentExists, setAgentExists] = useState<boolean>(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { state } = useLocation()
  const { user } = useAppContext()
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (state && user) {
      navigate(`/${state}`)
    }
  }, [state, user])

  useEffect(() => {
    sendAnalyticsEvent()
  }, [sendAnalyticsEvent])

  useEffect(() => {
    const req = async () => {
      try {
        if (user) {
          const res = await getCapacity()
          setCapacity(res)
        }
      } catch (error) {
        console.error("error fetching capacity", error)
      }
    }
    req()
  }, [user])

  useEffect(() => {
    const req = async () => {
      try {
        const agentId = id || getDefaultAgentId

        if (agentId === getDefaultAgentId) {
          return
        }
        if (!user) {
          return
        }
        const exists = await verifyAgentExists(agentId)
        setAgentExists(exists)
        if (!exists) {
          setAgentError(`Agent with ID ${agentId} does not exist`)
          return
        }

        const usage = await getAgentUsage(agentId)
        const remainingCredits = getAvailableCredits(usage)
        setOverUsage(remainingCredits < 0)
      } catch (error) {
        console.error("error fetching usage", error)
        setAgentError("Error checking agent availability")
      }
    }
    req()
  }, [id])

  return (
    <Stack direction={"column"} spacing={2}>
      <Header />
      <SnackbarWithText
        open={agentError !== ""}
        severity={SnackbarType.error}
        text={agentError}
        handleClose={() => setAgentError("")}
      />
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          px: { xs: 1, sm: 2, md: 3, lg: 4 },
          overflow: "hidden",
        }}
      >
        <Grid
          container
          spacing={isMobile ? 1 : 3}
          justifyContent="center"
          alignItems="center"
          sx={{
            p: { xs: 1, sm: 2, md: 3 },
            width: "100%",
            m: 0,
            maxWidth: "1440px",
          }}
        >
          <Grid item md={1} xl={2} />
          <Grid
            item
            xs={12}
            md={6}
            xl={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              maxWidth: {
                xs: "100%",
                sm: "600px",
                md: "100%",
              },
              mx: "auto",
              px: { xs: 1, sm: 2 },
            }}
          >
            <MediaSetup setPermissionsGranted={setPermissionsGranted} />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            xl={3}
            sx={{
              width: "100%",
              maxWidth: {
                xs: "100%",
                sm: "600px",
                md: "100%",
              },
              mx: "auto",
              px: { xs: 1, sm: 2 },
            }}
          >
            <UserInput
              permissionsGranted={permissionsGranted}
              capacity={capacity}
              overUsage={overUsage}
              agentExists={agentExists}
            />
          </Grid>
          <Grid item md={1} xl={2} />
        </Grid>
        {isMobile ? <AccountMobile /> : null}
      </Stack>
      <Background />
    </Stack>
  )
}
