import { Fab, SxProps, Theme } from "@mui/material"
import React from "react"

export function MainButton({
  onClick,
  disabled,
  text,
  small = false,
  sx,
}: {
  onClick: () => void
  disabled: boolean
  text: string
  small?: boolean
  sx?: SxProps<Theme>
}) {
  return (
    <Fab
      color="primary"
      variant="extended"
      onClick={onClick}
      disabled={disabled}
      sx={{
        textTransform: "none",
        width: {
          xs: "100%",
        },

        height: "53px",
        borderRadius: "10px",
        fontWeight: 600,
        fontSize: { xs: "14px", sm: "15px", md: "16px" },
        lineHeight: "130%",
        bgcolor: disabled ? "#DCD9E2" : "#40069E",
        "&:hover": { bgcolor: "#4019B6" },
        ...sx,
      }}
    >
      {text}
    </Fab>
  )
}
