import { Box, Typography } from "@mui/material"
import { CheckCircle } from "@phosphor-icons/react"
import React from "react"

export default function FeedbackSubmitted() {
  return (
    <Box
      position={"absolute"}
      bottom={50}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ backgroundColor: "#F5F5F5", borderRadius: "10px" }}
      padding={"1rem"}
      boxShadow={"0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
      gap={"1rem"}
    >
      <CheckCircle
        style={{
          backgroundColor: "#C8E846",
          color: "#5A03E2",
          borderRadius: "50%",
        }}
        size={32}
      />
      <Typography variant="body2" color={"#220738"} fontWeight={600}>
        Thanks for your feedback!
      </Typography>
    </Box>
  )
}
