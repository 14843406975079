import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React from "react"
import { useAppContext } from "../Context"

export function MediaSelectForm({
  name,
  icon,
  options,
  currentOption,
  onChange,
}) {
  const context = useAppContext()
  const disabled = !context.stream
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <FormControl
      variant="standard"
      sx={{
        color: "grey",
        width: "30%",
        background: "rgba(255, 255, 255, 0.2)",
        borderRadius: "1rem",
      }}
    >
      <Select
        value={currentOption}
        disabled={disabled}
        onChange={onChange}
        displayEmpty
        variant="standard"
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        inputProps={{ "aria-label": name }}
        sx={{
          fontSize: 14,
          ".MuiSelect-select": { color: "#000000" },
          "& .MuiSvgIcon-root": { color: disabled ? "#ccc" : "grey" },
          "&:before": { borderBottom: "none" },
          "&.Mui-disabled:before": { borderBottom: "none" },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.deviceId}
            sx={{ fontSize: 14 }}
            value={option.deviceId}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
