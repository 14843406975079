import { Stack } from "@mui/material"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import Background from "../../components/background"
import Feedback from "../../components/Feedback/Feedback"
import FeedbackSubmitted from "../../components/Feedback/FeedbackSubmitted"
import { Header } from "../../components/header"
import { MainButton } from "../../components/mainButton"
import { SnackbarWithText } from "../../components/snackbar"
import { logAnalyticsEvent } from "../../external/analytics"
import { endCallDb } from "../../external/firestore"
import { AnalyticsEvents } from "../../types/Analytics"
import { SnackbarType } from "../../types/UI"
import { useAppContext, useAppDispatchContext } from "../Context"

export function PostScreen() {
  const dispatch = useAppDispatchContext()
  const context = useAppContext()
  const email = context.user.email

  const navigate = useNavigate()

  const [summaryOpen, setSummaryOpen] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [headline, setHeadline] = useState("You left the meeting")
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  const handleEndCallAndSummary = async () => {
    await endCallDb(context.call.id)
    setSummaryOpen(true)
    console.log("A summary of the meeting was sent to ", email)
    setButtonsDisabled(true)
    setHeadline("Your meeting has ended")
    logAnalyticsEvent(AnalyticsEvents.SummaryRequested)
  }

  const rejoin = async () => {
    logAnalyticsEvent(AnalyticsEvents.Rejoined)
    navigate(`/rooms/${context.call.id}`)
  }

  const handleClose = () => {
    setFeedbackOpen(false)
    setSummaryOpen(false)
  }

  return (
    <>
      <Header />
      <Stack
        direction={"column"}
        spacing={4}
        sx={{
          height: "90vh",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography variant="h4">{headline}</Typography>
        <MainButton
          sx={{ maxWidth: "300px" }}
          onClick={rejoin}
          disabled={buttonsDisabled}
          text="Rejoin"
        />
        <Button
          variant="text"
          onClick={handleEndCallAndSummary}
          disabled={buttonsDisabled}
          sx={{ textTransform: "none", color: "#40069E" }}
        >
          End call and send me a summary
        </Button>
        <SnackbarWithText
          open={summaryOpen}
          severity={SnackbarType.success}
          text={`A summary of the meeting was sent to ${email}`}
          handleClose={handleClose}
        />
        <br></br>
        {feedbackSubmitted ? (
          <FeedbackSubmitted />
        ) : (
          <Feedback
            visible={feedbackOpen}
            onClose={() => setFeedbackOpen(false)}
            onOpen={() => setFeedbackOpen(true)}
            onSubmit={() => setFeedbackSubmitted(true)}
          />
        )}
        <Background />
      </Stack>
    </>
  )
}
